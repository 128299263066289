var ready;

import Rails from '@rails/ujs';

ready = function() {
  $(document).off('submit', 'form.changes-would');
  return $(document).on('submit', 'form.changes-would', function(e) {
    var app_code, app_name, app_type, form, method, project_code, ref, ref1, ref2, submitButton;
    e.preventDefault();
    form = $(this);
    method = ((ref = form.attr('method')) != null ? ref.toUpperCase() : void 0) === 'POST' ? ((ref1 = form.find('input[name="_method"]').val()) != null ? ref1.toUpperCase() : void 0) || 'POST' : (ref2 = form.attr('method')) != null ? ref2.toUpperCase() : void 0;
    if (method === 'POST') {
      form[0].submit();
      return;
    }
    submitButton = form.find('input[type="submit"]');
    submitButton.prop('disabled', true);
    project_code = form.data('project-code');
    app_code = form.data('app-code');
    app_name = form.data('app-name');
    app_type = form.data('app-type');
    return Rails.ajax({
      url: `/p/projects/${project_code}/${app_type}_apps/${app_code}/changes_would`,
      type: 'PATCH',
      dataType: 'json',
      data: form.serialize(),
      success: function(data) {
        var prompt;
        prompt = null;
        if (data.automatically_deploy) {
          prompt = `${app_name} will redeploy after saving. Continue?`;
        } else if (data.automatically_deploy_routing) {
          prompt = `After saving, ${app_name} will continue running while its routing is updated. Continue?`;
        }
        if (prompt) {
          if (confirm(prompt)) {
            return form[0].submit();
          } else {
            return submitButton.prop('disabled', false);
          }
        } else {
          return form[0].submit();
        }
      },
      error: function(xhr, resp, text) {
        console.error(xhr, resp, text);
        return submitButton.prop('disabled', false);
      }
    });
  });
};

$(document).on('turbolinks:load', ready);
